.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  background-color: #ddd;
  color: #444;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  background-color: #cde;
  padding: 4px;
  border-bottom: 2px solid black;
}

.headerTitle {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 24px;
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
  padding: 8px;
  flex: 1;
  gap: 8px;
}
