.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  gap: 4px;
}

.header {
  display: flex;
  gap: 8px;
  // padding: 4px;
  .subViewSelect {
    background-color: white;
  }
}

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 2px 4px;
  color: #666;
  font-size: 11px;
}

.overlay {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #0006;
}

.overlayContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  font-weight: bold;
  gap: 12px;
}

.subViewButtons {
  padding: 3px 6px;
  border-radius: 0;

  &.subViewButtonSelected {
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
  }
}
