.container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;
}

.header {
  margin: 0 0 8px 0;
}

.title {
  margin: 0;
  font-size: 24px;
  text-align: center;
}

.cell {
  // display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1px;
  text-align: center;
  text-transform: none;
  font-size: 14px;
  overflow: hidden;
  word-break: break-word;
}

.cellLeft {
  justify-content: flex-start;
}

.leagues {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  width: 100%;
}

.league {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.leagueHeader {
  display: flex;
  align-items: center;
  padding: 6px;
  justify-content: flex-start;
}

.leagueEvents {
  display: flex;
  flex-direction: column;
  flex: 1;
  // gap: 2px;
}

.gameTrackersContainer {
  display: flex;
  width: 100%;
}

.gameTrackerIframe {
  border: 1px solid #555;
  height: 222px;
}
