.container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;
}

.header {
  margin: 0 0 8px 0;
}
.title {
  margin: 0;
  font-size: 24px;
  text-align: center;
}
.subtitle {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  text-align: center;
}

.teamsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.row {
  display: grid;
  grid-template-columns: 75px repeat(7, 1fr) 30px;
  // grid-template-columns: 100px 100px repeat(6, 20px) 30px;
  // grid-template-columns: repeat(9, minmax(0px, 1fr));
  padding: 2px 4px;
  border-top: 1px solid #0002;
  &:first-child {
    border-top: 0;
  }
}

.cell {
  // display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 1px;
  text-align: center;
  text-transform: none;
  font-size: 14px;
  overflow: hidden;
  word-break: break-word;
}

.cellLeft {
  justify-content: flex-start;
}

.teamsHeader {
  border-bottom: 2px solid #ccc;

  .cell {
    font-weight: bold;
    text-transform: uppercase;
    align-items: flex-end;
  }
}

.teamsBody {
  display: flex;
  flex-direction: column;
  // width: 100%;
  // max-width: 600px;

  // > * {
  //   margin-bottom: 8px;
  //   &:last-child {
  //     margin-bottom: 0;
  //   }
  // }
}
