.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.entriesContainer {
  display: flex;
  justify-content: center;
  flex: 1;
}
.entries {
  display: flex;
  flex-direction: column;
}

.spacer {
  width: 16px;
}

.entry {
  display: grid;
  grid-template-columns: 40px 60px 1fr 50px 1fr;
  // align-items: center;
  font-size: 11px;
  line-height: 1;
  white-space: nowrap;
  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1px 2px;
    // margin-right: 4px;
    // &:last-child {
    //   margin-right: 0;
    // }
  }
}

.opponent {
  // display: flex;
  // width: 40px;
  justify-content: flex-start;
}

.matchupButton {
  // display: flex;
  font-size: inherit;
  padding: 0;
  margin: 0;
  min-width: unset;
  text-transform: none;
}

.matchupIcon {
  margin-left: 2px;
  font-size: 8px;
}

.situation {
  text-align: center;
  text-transform: uppercase;
  &.situationLeading {
    background-color: #8082;
    outline: 2px solid #8088;
    border-radius: 8px;
    outline-offset: -2px;
    font-weight: bold;
  }
}

.inning {
  text-transform: uppercase;
  &.inningEarly {
    background-color: #F002;
    outline: 2px solid #F008;
    border-radius: 8px;
    outline-offset: -2px;
    font-weight: bold;
  }
}

.event {
  text-transform: uppercase;
  justify-content: flex-start;
}
