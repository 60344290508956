.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  line-height: 1;
  text-transform: uppercase;
  gap: 3px;
}

.statusChip {
  display: flex;
  padding: 6px;
  border-radius: 6px;
  color: white;
  background-color: black;
}

.statusPrematch {
  background-color: #757575;
}
.statusFinal {
  background-color: #212121;
}
.statusLive {
  background-color: #7986cb;
  &.isRunning {
    background-color: #3f51b5;
  }
  &.isIntermission {
    background-color: #757575;
  }
}

.statusSituation {
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: #c62828;

  .strengthNum {
    font-size: 13px;
  }
  .strengthNumSep {
    font-weight: normal;
    font-size: 8px;
  }
}

.periodTime {
  display: flex;
  align-items: center;
  gap: 8px;

  .periodNum {
  }
  .periodTimeRemaining {
  }
}
