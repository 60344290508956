.container {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  color: black;
}

.loadingContainer {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #fffa;
}
.status {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  width: 100px;
}

.content {
  display: grid;
  grid-template-columns: 40px 48px max-content 50px 1fr 50px 1fr;
  grid-template-rows: 1fr 4px 1fr;
}

.contentLoading {
  visibility: hidden;
}

.linescore {
  display: grid;
  grid-template-columns: 30px 30px repeat(3, 26px);
  grid-template-rows: 1fr;
}

.enStats {
  display: grid;
  grid-template-columns: repeat(3, 80px);
  grid-template-rows: 1fr;
  position: relative;

  .enStatsMask {
    position: absolute;
    inset: 0;
    display: none;
    &.enStatsMaskActive {
      display: flex;
      background-color: #fffa;
    }
  }

  .cellFloating {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    color: #333;
    font-size: 10px;
    line-height: 1;

    &.cellFloatingTop {
      top: -10px;
    }
    &.cellFloatingBottom {
      bottom: -12px;
    }
    .middleLine {
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: #0005;
      top: 4px;
    }
    .cellFloatingBottomLabel {
      position: relative;
      padding: 0 3px;
      background-color: white;
    }
  }

  .enStatsContentNoData {
    text-transform: none;
    line-height: 1;
    color: #666;
    font-size: 10px;
  }
  .enStatsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 75px;
    flex: 1;
    gap: 4px;
    line-height: 1;
    text-transform: none;

    .enStatsContentTop {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      font-size: 13px;
    }

    .enStatsContentBottom {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 11px;
    }

    .enStatsPerc {
      background-color: #616161;
      color: white;
      padding: 3px 4px;
      width: 32px;
      flex: 1;
      border-radius: 4px;
      font-size: 13px;
      font-weight: bold;
    }

    .enStatsGood {
      background-color: #2e7d32;
    }
    .enStatsAverage {
      background-color: #616161;
    }
    .enStatsBad {
      background-color: #d32f2f;
    }
  }
}

.spacerRow {
  grid-column: 1/-1;
}

.teamContainer {
  display: contents;
}

.cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  line-height: 1;
}

.teamLogo {
  padding: 2px 4px;
  .logo {
    width: 32px;
    height: 24px;
  }
}
.team {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.score {
  display: flex;
  justify-content: flex-end;
  // gap: 8px;
  color: black;
  font-size: 20px;
  font-weight: bold;
  // padding-right: 12px;
}

.sogs {
  display: flex;
  justify-content: flex-start;
}

.p1,
.p2,
.p3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.shots {
  color: #555;
  font-size: 9px;
}
