.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.gridContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

// .outcomeLabel {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 60px;
//   padding: 4px 0;
//   border-radius: 3px;
//   font-size: 16px;
//   font-weight: bold;
//   line-height: 1;
//   color: white;
//   background-color: hsl(0, 0%, 50%);

//   &.outcomeLabelENG {
//     background-color: hsl(220, 50%, 50%);
//   }
//   &.outcomeLabelCBG {
//     background-color: hsl(300, 50%, 50%);
//   }
// }

// .goalCellGray {
//   background-color: #aaa;
// }

// .goalCellGreen {
//   background-color: rgb(140, 200, 140);
// }
// .goalCellRed {
//   background-color: rgb(249, 160, 160);
// }

.gridHeaderCell {
  display: flex;
  // align-items: center;
  // justify-content: center;
  font-weight: bold;

  :global(.ag-sort-indicator-container) {
    // display: none;
    display: flex;
    align-items: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 10px;
    font-weight: normal;
    > * {
      padding: 0;
    }
  }
}

.gridBodyCell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  gap: 6px;
  flex-shrink: 0;
}

.goalsForCell {
  // border-left: 1px solid red;
  // font-weight: bold;
}

// .primaryTeamCell {
//   font-weight: bold;
// }

.teamNameCell {
  gap: 6px;
  height: 100%;
  font-weight: bold;
}

.teamName {
  font-size: 16px;
  width: 40px;
  text-align: left;
  flex-shrink: 0;
  line-height: 1;
}

.teamLogo {
  width: 26px;
  // height: 100%;
}

// .score {
//   width: 40px;
// }

// .winLossTag {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 2px;
//   background-color: hsl(0, 50%, 40%);
//   color: white;
//   border-radius: 3px;
//   height: 10px;
//   width: 10px;
//   line-height: 1;
//   font-size: 9px;
//   font-weight: bold;
//   &.isWinner {
//     background-color: hsl(120, 50%, 40%);
//   }
// }

.valueWithPercent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;
    width: 20px;
  }
  .percentage {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: blue;
    color: white;
    line-height: 1;
    font-size: 11px;
    font-weight: bold;
    padding: 4px 2px;
    border-radius: 3px;
    overflow: hidden;
    width: 35px;

    .percentageChar {
      font-weight: normal;
      margin-left: 2px;
      font-size: 8px;
    }
  }
}
