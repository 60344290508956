.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 400px;
  overflow: hidden;
}

.filtersContainer {
  display: flex;
  gap: 8px;
  padding: 6px 8px;

  .teamSelect {
    width: 80px;
    padding: 8px;
  }
}

.teamOption {
  padding: 4px 6px;
}

.teamOptionContent {
  display: flex;
  align-items: center;
  gap: 8px;

  .teamLogo {
    width: 20px;
    height: 20px;
  }

  .teamCode {
    font-size: 16px;
    font-weight: bold;
  }
}

.gridContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.outcomeLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  padding: 4px 0;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: white;
  background-color: hsl(0, 0%, 50%);

  &.outcomeLabelENG {
    background-color: hsl(220, 50%, 50%);
  }
  &.outcomeLabelCBG {
    background-color: hsl(300, 50%, 50%);
  }
}

.goalCellGray {
  background-color: #aaa;
}

.goalCellGreen {
  background-color: rgb(140, 200, 140);
}
.goalCellRed {
  background-color: rgb(249, 160, 160);
}

.gridHeaderCell {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  font-weight: bold;
}

.gridBodyCell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  gap: 6px;
  flex-shrink: 0;
}

.primaryTeamCell {
  font-weight: bold;
}

.teamNameCell {
  gap: 6px;
  height: 100%;
}

.teamName {
  font-size: 16px;
  width: 50px;
  text-align: right;
  flex-shrink: 0;
  line-height: 1;
}

.teamLogo {
  width: 26px;
  // height: 100%;
}

.score {
  width: 40px;
}

.winLossTag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: hsl(0, 50%, 40%);
  color: white;
  border-radius: 3px;
  height: 10px;
  width: 10px;
  line-height: 1;
  font-size: 9px;
  font-weight: bold;
  &.isWinner {
    background-color: hsl(120, 50%, 40%);
  }
}
