.gridHeaderCell {
  font-weight: bold;
}

.gridBodyCell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  gap: 6px;
  flex-shrink: 0;
  height: 100%;
}

.primaryTeamCell {
  font-weight: bold;
}

.teamNameCell {
  gap: 6px;
  height: 100%;
}

.teamName {
  font-size: 16px;
  width: 50px;
  text-align: right;
  flex-shrink: 0;
  line-height: 1;
}

.teamLogo {
  width: 20px;
  aspect-ratio: 1;
}

.resultContent {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 4px;
}

.score {
  display: flex;
  align-items: center;
  // width: 44px;
  gap: 2px;
  .runs {
    font-size: 14px;
  }
  .suffix {
    font-size: 8px;
    margin-left: 2px;
  }
}
.icon {
  font-size: 11px;
  // display: flex;
  // width: 12px;
  // height: 12px;
}

.winLossTag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  background-color: hsl(0, 50%, 40%);
  color: white;
  border-radius: 3px;
  height: 10px;
  width: 10px;
  line-height: 1;
  font-size: 9px;
  font-weight: bold;
  &.isWinner {
    background-color: hsl(120, 50%, 40%);
  }
}

.resultCell,
.linescoreCell {
  display: flex;
  justify-content: flex-start;
}

.innings {
  --num-innings: 9;
  &.inningsExtra {
    --num-innings: 10;
  }
  display: grid;
  grid-template-columns: 35px repeat(var(--num-innings), 14px);
  grid-template-rows: repeat(2, 1fr);
  border: 1px solid #ddd;
  padding: 0;
  margin: 2px;
  gap: 0;
  background-color: white;

  .inningHalf {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-size: 12px;
    vertical-align: middle;
    // outline: 1px solid red;
    padding: 2px;

    &.win {
      background-color: rgb(200, 255, 200);
    }
    &.lose {
      background-color: rgb(255, 200, 200);
    }
  }
  .inningAway {
    border-bottom: 1px solid #ddd;
  }
  .inningHome {
  }
  .team {
    background-color: #ddd;
    &.currentTeam {
      font-weight: bold;
    }
  }
}

.startingPitchers {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-template-rows: repeat(2, 1fr);
  border: 1px solid #ddd;
  padding: 0;
  margin: 2px;
  gap: 0;
  background-color: white;
  width: 100%;
  // height: 100%;

  .cell {
    display: flex;
    align-items: center;
    // justify-content: center;
    line-height: 1;
    font-size: 12px;
    vertical-align: middle;
    // outline: 1px solid red;
    padding: 2px;
  }
  .cellAway {
    border-bottom: 1px solid #ddd;
  }
  .team {
    justify-content: center;
    background-color: #ddd;
  }
}
