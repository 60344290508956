.container {
  display: flex;
  gap: 6px;
  align-items: center;

  .dayLabel {
    line-height: 1;
    font-weight: bold;
    font-size: 15px;
  }
}
