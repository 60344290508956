.container {
  display: flex;
  flex-direction: column;
  border-radius: 0;
  // color: #333;
  font-size: 13px;

  .teamCode {
    font-weight: bold;
    font-size: 20px;
    color: #000;
  }

  .teamHeader {
    padding: 0;
    border-radius: 0;
    max-width: unset;
  }

  .teamHeaderLeft {
    justify-content: flex-start;
  }

  .teamHeaderRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .primaryStatCell {
    font-weight: bold;
    font-size: 18px;
  }
}

.teamBody {
  padding: 4px 16px;
  background-color: #8881;
}
