.home {
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  padding: 32px;
  font-size: 64px;
}
.item {
  font-size: 48px;
  font-weight: bold;
  line-height: 1;
  padding: 32px;
}
