.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.matchups {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.matchupContainer {
  display: flex;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;

  .matchupButton {
    display: flex;
    flex: 1;
    padding: 0;
  }
  .matchup {
    flex: 1;
    padding: 12px;
  }
}

.singleMatchupTop {
  display: flex;
  align-items: center;
}
