.app {
  // background-color: #e0f2f1;
  display: flex;
  flex-direction: column;
  flex: 1;

  background: #ccc;
  // background-color: #567;

  // background: linear-gradient(315deg, #ddd 0%, #bbb 100%);
  // background: linear-gradient(315deg, #ddd 0%, #bbb 100%);

  // background: rgb(227,242,253);
  // background: linear-gradient(145deg, rgba(227,242,253,1) 0%, rgba(187,222,251,1) 100%);
}

a {
  color: unset;
  // text-decoration: underline;
  // text-decoration-style: dotted;
  // text-decoration-color: aquamarine;
  // text-decoration-thickness: from-font;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
